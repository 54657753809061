import { IWPTerm, IWPTermRelationship } from "../@types/Wordpress";
import { WpPostEntity } from "../hooks/api/generated";

export function getMetaValue(item: WpPostEntity, key: string): string {
  return item?.wpPostmeta?.find(
    (meta: { [x: string]: string }) => meta?.meta_key === key
  )?.meta_value;
}

export function extractTextAndTruncate(
  htmlString: string,
  maxLength: number
): string {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  const text = div.textContent ?? div.innerText ?? "";
  return text.length > maxLength ? `${text?.substring(0, maxLength)}...` : text;
}

export function extractTermRelationshipByType(
  item: WpPostEntity,
  type: string
): IWPTerm[] {
  const terms: IWPTerm[] = [];
  item?.wpTermRelationship?.forEach(
    ({ termTaxonomy: { taxonomy, term } }: IWPTermRelationship) => {
      if (taxonomy === type) {
        terms.push(term);
      }
    }
  );
  return terms;
}
